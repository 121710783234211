import React from "react";
import './faq.css';

function Faq01() {
    return (
        <div className="faq_01">
            <div className="container">
                <div className="row">
                    <div className="faq_01__content">
                        <div className="faq_01__content__top">
                            <p className="faq_01__content__title">All Rise AI FAQ</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq01;