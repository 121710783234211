import React from "react";
import './product.css';
import vod3 from '../../img/vod3.mp4';
import vod4 from '../../img/vod4.mp4';
import vod5 from '../../img/vod5.mov';
import { Reveal } from "../common/Reveal";

function Product03() {
    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }

    return (
        <div className="product_03">
            <div className="container">
                <div className="row">
                    <div className="product_03__content">
                        <div className="product_03__content__left">
                            <p className="product_03__content__title">
                                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    Streamlined Communication
                                </Reveal>
                            </p>
                            <p className="product_03__content__description">
                                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    Automate routine client interactions with precision. Our AI seamlessly handles inquiries, ensuring consistent and professional communications.
                                </Reveal>
                            </p>
                        </div>
                        <div className="product_03__content__right">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <video width="100%" autoPlay muted loop>
                                    <source src={vod3} type="video/mp4" />
                                </video>
                            </Reveal>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="product_03__content">
                        <div className="product_03__content__left">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <video width="100%" autoPlay muted loop>
                                    <source src={vod4} type="video/mp4" />
                                </video>
                            </Reveal>
                        </div>
                        <div className="product_03__content__right">
                            <p className="product_03__content__title">
                                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    Real-Time Data Processing
                                </Reveal>
                            </p>
                            <p className="product_03__content__description">
                                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    Benefit from instantaneous data handling that accelerates decision-making. Our AI analyzes and processes information as it comes, keeping your cases moving forward efficiently.
                                </Reveal>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="product_03__content">
                        <div className="product_03__content__left">
                            <p className="product_03__content__title">
                                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    Customized to Your Needs
                                </Reveal>
                            </p>
                            <p className="product_03__content__description">
                                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    Tailor the AI to meet the specific demands of your practice. With high customization capabilities, adapt the system to align perfectly with your firm's processes and client needs.
                                </Reveal>
                            </p>
                        </div>
                        <div className="product_03__content__right">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <video width="100%" autoPlay muted loop>
                                    <source src={vod5} type="video/mp4" />
                                </video>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product03;