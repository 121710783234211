import React from "react";
import './product.css';

function Product05() {
    return (
        <div className="product_05">
            <div className="container">
                <div className="row">
                    <div className="product_05__content">
                        <div className="product_05__content__center">
                            <div className="product_05__content__title">
                                TRANSFORM YOUR BUSINESS WITH <b>ALL RISE AI</b>
                            </div>
                            <div className="product_05__content__cta">
                                <a href="/contact" className="cta-btn">Schedule a Demo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product05;