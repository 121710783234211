import React from "react";
import './product.css';
import qualify from '../../img/qualify.png';
import emailsms from '../../img/smsemail.webp';
import dashboard from '../../img/dashboard.webp';
import livetransfer from '../../img/livetransfer.webp';
import transcript from '../../img/transcript.webp';
import availability from '../../img/availability.webp';
import compliance from '../../img/compliance.webp';
import analytics from '../../img/analytics.webp';
import scalability from '../../img/scalability.webp';
import interactions from '../../img/interactions.webp';
import { Reveal } from "../common/Reveal";

function Product04() {
    // Accordion variables
    const [showMore, setShowMore] = React.useState(false);
    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }
    return (
        <div className="product_04">
            <div className="container">
                <div className="row">
                    <div className="product_04__content_top">
                        <h2 className="product_04__content_title">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                All Rise AI Features
                            </Reveal>
                        </h2>
                        <p className="product_04__content_description">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                Empowering Your Legal Practice with Cutting-Edge Technology
                            </Reveal>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="product_04__content_center">
                        <div className="product_04__card">
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={qualify} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Automated Client Pre-Screening
                                    </h3>
                                    <p className="product_04__card_description">
                                        Rapidly evaluates potential clients based on predefined criteria to determine case eligibility.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card">
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={emailsms} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Multi-Channel Communication
                                    </h3>
                                    <p className="product_04__card_description">
                                        Facilitates client interactions across various platforms including phone calls, SMS, and email.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card">
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={dashboard} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Real-Time Data Processing
                                    </h3>
                                    <p className="product_04__card_description">
                                        Instantly analyzes incoming data to make timely eligibility decisions and updates.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card" style={{ display: showMore ? "unset" : "none" }}>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={interactions} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Customizable Interaction Scripts
                                    </h3>
                                    <p className="product_04__card_description">
                                        Allows for the tailoring of communication scripts to fit specific legal requirements and client needs.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card" style={{ display: showMore ? "unset" : "none" }}>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={livetransfer} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Seamless Transfer to Human Agents
                                    </h3>
                                    <p className="product_04__card_description">
                                        Automatically redirects eligible cases to intake centers for human interaction when necessary.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card" style={{ display: showMore ? "unset" : "none" }}>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={transcript} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Detailed Call Transcripts
                                    </h3>
                                    <p className="product_04__card_description">
                                        Provides comprehensive transcripts of all AI-client interactions for record-keeping and quality control.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card" style={{ display: showMore ? "unset" : "none" }}>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={availability} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        24/7 Availability
                                    </h3>
                                    <p className="product_04__card_description">
                                        Ensures round-the-clock operation, enhancing client service without the need for continuous human oversight.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card" style={{ display: showMore ? "unset" : "none" }}>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={compliance} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Compliance Adherence
                                    </h3>
                                    <p className="product_04__card_description">
                                        Configured to comply with legal standards and privacy regulations to protect client information.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card" style={{ display: showMore ? "unset" : "none" }}>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={analytics} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Performance Analytics
                                    </h3>
                                    <p className="product_04__card_description">
                                        Offers insights into interaction quality, client feedback, and overall system efficiency to continually refine the intake process.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                        <div className="product_04__card" style={{ display: showMore ? "unset" : "none" }}>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                <div><img src={scalability} alt="Placeholder" /></div>
                                <div>
                                    <h3 className="product_04__card_title">
                                        Scalability
                                    </h3>
                                    <p className="product_04__card_description">
                                        Designed to handle varying volumes of client inquiries, scaling as demand increases without loss of performance.
                                    </p>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="product_04__content_center">
                        <div className="product_04__btn">
                            <button href="/" className="cta-btn" onClick={() => setShowMore(!showMore)}>
                                {showMore ? "Show Less" : "Show More"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product04;