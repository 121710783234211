import React from "react";
import Thankyou from "src/components/thankyou/ThankYou";
import 'src/css/font.css';

function ThankyouP() {
    return (
        <div>
            <Thankyou />
        </div>
    );
}

export default ThankyouP;