import React from "react";
import "./header.css";
import logo from "src/img/All Rise - Text-01.svg";
import icon from "src/img/logo.png";


function Header() {

    const [showMenu, setShowMenu] = React.useState(false);

    return (
        <div className="header">
            <div className="container">
                <div className="row">
                    <div className="header__container flex">
                        <div className="header__left">
                            <img src={logo} alt="arai-logo" />
                            <img src={icon} alt="arai-logo" />
                        </div>
                        <div className="header__center">
                            <ul className="flex-between nav">
                                <li className="nav-item">
                                    <a href="/" className={
                                        window.location.pathname === "/" ? "nav-link active" :
                                            "nav-link"}>
                                        Home
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/allriseai" className={
                                        window.location.pathname === "/allriseai" ? "nav-link active" :
                                            "nav-link"}>
                                        Our Product
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/pricing" className={
                                        window.location.pathname === "/pricing" ? "nav-link active" :
                                            "nav-link"}>
                                        Pricing
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/faq" className={
                                        window.location.pathname === "/faq" ? "nav-link active" :
                                            "nav-link"}>
                                        FAQ
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/referral-program" className={
                                        window.location.pathname === "/referral-program" ? "nav-link active" :
                                            "nav-link"}>
                                        Referral Program
                                    </a>
                                </li>
                            </ul>
                            <div className="mobile-nav">
                                <ul className={showMenu ? 'active' : ''}>
                                    <li className="nav-item">
                                        <a href="/" className="nav-link">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/allriseai" className="nav-link">Our Product</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/pricing" className="nav-link">Pricing</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/faq" className="nav-link">FAQ</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/referral-program" className="nav-link">Referral Program</a>
                                    </li>
                                </ul>

                                <div className="mobile-nav__btn" onClick={() => setShowMenu(!showMenu)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                        </div>
                        <div className="header__right">
                            <a href="/contact" className="btn btn-primary bebas">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;