import React from "react";
import './product.css';
import { Reveal } from "../common/Reveal";

function Product01() {
    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }
    return (
        <div className="product_01">
            <style jsx="true">{`
            .watermark {
                display: none!important;
            }
            `}</style>
            <div className="container">
                <div className="row">
                    <div className="product_01__content">
                        <div className="product_01__content__left">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="product_01__content__title">Cutting-Edge AI Solutions</p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="product_01__content__description">Our platform utilizes advanced AI to automate and enhance client interactions, making your operations more efficient.</p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <a href="/contact" className="cta-btn">See the Technology</a>
                            </Reveal>
                        </div>
                        <div className="product_01__content__right">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product01;