import React from "react";
import Product01 from "src/components/product/Product01";
import Product02 from "src/components/product/Product02";
import Product03 from "src/components/product/Product03";
import Product04 from "src/components/product/Product04";
import Product05 from "src/components/product/Product05";
import 'src/css/font.css';

function Product() {
    return (
        <div>
            <Product01 />
            <Product02 />
            <Product03 />
            <Product04 />
            <Product05 />
        </div>
    );
}

export default Product;