import React, { useEffect } from "react";
import './referral.css';

function ReferralForm() {
    const [firstName, setFirstname] = React.useState("");
    const [lastName, setLastname] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [file, setFile] = React.useState("");
    const [toc, setToc] = React.useState(false);

    const [sending, setSending] = React.useState(false);
    const [submited, setSubmited] = React.useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!toc)
            return;

        const data = new FormData();
        data.append('first_name', firstName);
        data.append('last_name', lastName);
        data.append('email', email);
        data.append('file', file);

        setSending(true);

        let response = await fetch('https://webhook.site/RewardfulSignUp', {
            method: 'POST',
            body: data,
        });

        if (response.ok) {
            setSending(false);
            setSubmited(true);
        }



    };

    const downloadW9 = (e) => {
        e.preventDefault();
        window.open('https://www.irs.gov/pub/irs-pdf/fw9.pdf', '_blank');
    }


    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'formSubmitted') {
                setTimeout(() => {
                    window.location.href = "/referral";
                }, 1000);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };

    }, []);

    const formContent = () => {
        if (sending) {
            return (
                <div className="referral_form__content__form__input">
                    <p>Sending...</p>
                </div>
            );
        }

        if (submited) {
            return (
                <div className="referral_form__content__form__input">
                    <p>Submited</p>
                </div>
            );
        }

        return (
            <div>

                <div className="referral_form__content__form__input">
                    <label htmlFor="first_name">First Name</label>
                    <input
                        type="text"
                        name="first_name" placeholder="First Name"
                        onChange={(e) => setFirstname(e.target.value)}
                        value={firstName}
                        required />
                </div>
                <div className="referral_form__content__form__input">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                        type="text"
                        name="last_name" placeholder="Last Name"
                        onChange={(e) => setLastname(e.target.value)}
                        value={lastName}
                        required />
                </div>
                <div className="referral_form__content__form__input">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email" placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required />
                </div>
                <div className="referral_form__content__form__input">
                    <label htmlFor="file">Upload your filled form W-9</label>
                    <small>Download the form <a href="/" onClick={downloadW9}>here</a> and upload it</small>
                    <input
                        type="file"
                        name="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        required />
                </div>
                <div className="referral_form__content__form__check">
                    <input
                        type="checkbox"
                        name="toc"
                        id="toc"
                        onChange={(e) => setToc(e.target.checked)}
                        value={toc}
                        required />
                    <label htmlFor="toc">I agree to this referral program's <a href="/referral-terms" target="_blank">terms and conditions</a></label>
                </div>
                <div className="referral_form__content__form__input">
                    <button type="submit">Submit</button>
                </div>
            </div>
        );
    }


    return (
        <div className="referral_form">
            <div className="container">
                <div className="row">
                    <div className="referral_form__content">
                        {/* Create a form with: title , subtitle, a card that cointains: description and the form itself with inputs: first name, last name, email, a file input and a toc checkbox and a button */}
                        <div className="referral_form__content__top">
                            <div>
                                <p className="referral_form__content__subtitle">
                                    All Rise AI
                                </p>
                                <p className="referral_form__content__title">
                                    Lifelong Partners Program
                                </p>

                            </div>

                            <div className="referral_form__content__form">
                                <p className="referral_form__content__info">
                                    Join our referral program and become a vital part of our growing community. We pay a commission for each active client you refer us. 10% for the first 12 months converting to 5% for life thereafter. It's our way of saying thank you for helping us expand our reach and strengthen our community of dedicated users. Start sharing and earning today!
                                </p>

                                <hr />
                                <form onSubmit={handleSubmit} method="POST">
                                    {formContent()}
                                </form>
                                <small>
                                    <center>
                                        You will receive an email with the next steps after submitting the form.
                                    </center>
                                </small>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReferralForm;