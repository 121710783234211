import React, { useState } from 'react';
import numeral from 'numeral';
import './pricing.css';
import { refPlans } from './constants';

function Calculator(props) {
  const [minutes, setMinutes] = useState(null);
  const [overhead, setOverhead] = useState(null);
  const [bestPlan, setBestPlan] = useState(null);
  const [errors, setErrors] = useState({ minutes: '', overhead: '' });

  const handleCalculation = (event) => {
    event.preventDefault();
    const newErrors = {};
    if (minutes === null) {
      newErrors.minutes = 'Minutes is mandatory ';
    }
    if (overhead === null) {
      newErrors.overhead = 'Overhead is mandatory';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setBestPlan(null);
      return;
    } else setErrors({});


    const newCalculatedPlans = refPlans.map(plan => {
      const extraMinutes = minutes - plan.minutesIncluded;
      const extraMinutesCost = extraMinutes >= 0 ? plan.perMinute * extraMinutes : 0;
      const carrierChargeCost = plan.carrierCharge * minutes;
      const totalCost = plan.baseCost + extraMinutesCost + carrierChargeCost;
      const savings = overhead - totalCost;
      return {
        ...plan,
        extraMinutes,
        extraMinutesCost,
        carrierChargeCost,
        totalCost,
        savings,
      };
    });

    // Get lowest total cost plan on first and then show Essentialls, Standard and Professional plans

    setBestPlan(newCalculatedPlans.sort((a, b) => a.totalCost - b.totalCost)[0]);
  }

  return (
    <div className="pricing_01__content__calculator__content">
      <div className="pricing_01__content__calculator__form__card">
        <form onSubmit={handleCalculation}>
          <div className="pricing_01__content__calculator__input__title">
            Pricing Calculator
          </div>
          <div className="pricing_01__content__calculator__input__description">
            Please enter accurate information to determine the highest cost savings plan for your specific use case.
          </div>
          <div className="pricing_01__content__calculator__minutes__section">
            <label className="pricing_01__content__calculator__input__label">
              Total Monthly Minutes Used (Prior Full Month)
            </label>
            {errors.minutes && (
              <p className="pricing_01__content__calculator__input__error">
                {errors.minutes}
              </p>
            )}
            <input
              className={`pricing_01__content__calculator__input__box ${errors.minutes ? 'error' : ''}`}
              type="number"
              min={0}
              value={minutes}
              onChange={(e) => setMinutes(e.target.value || null)}
              placeholder="Enter the approximate number of hours you would use per month..."
            />
          </div>
          <div className="pricing_01__content__calculator__minutes__section">
            <label className="pricing_01__content__calculator__input__label">
              Total Current Overhead ($) (Prior Full Month)
              <br />
              <small>(Include everything AI replaces– Wages, Licenses, Hiring Costs, Telcom, Rent, etc)</small>
            </label>
            {errors.overhead && (
              <p className="pricing_01__content__calculator__input__error">
                {errors.overhead}
              </p>
            )}
            <input
              className={`pricing_01__content__calculator__input__box ${errors.overhead ? 'error' : ''
                }`}
              type="number"
              min={0}
              pattern='^\d+(?:\.\d{1,2})?$'
              value={overhead}
              onChange={(e) => setOverhead(e.target.value || null)}
              placeholder="Enter the approximate overall expenses you would have per month..."
            />
          </div>
          <div className="pricing_01__content__calculator__submit__button">
            <button className="cta-btn" type="submit">Calculate</button>
          </div>
        </form>
      </div>
      <div className="pricing_01__content__calculator__plan__card">
        {!!bestPlan ? (
          <div className="pricing_01__content__calculator__best__plan">
            {(bestPlan.savings >= 0 ? (
              <>
                <div className="pricing_01__content__calculator__best__plan__saving__box">
                  <div className="pricing_01__content__calculator__best__plan__title">
                    Best Plan
                  </div>
                  <div className="pricing_01__content__calculator__best__plan__saving">
                    {bestPlan.title}
                  </div>
                </div>
                <div className="pricing_01__content__calculator__best__plan__saving__box">
                  <div className="pricing_01__content__calculator__best__plan__title">
                    Estimated Cost
                  </div>
                  <div className="pricing_01__content__calculator__best__plan__saving">
                    ${numeral(bestPlan.totalCost).format('0,00')}/mo
                  </div>
                </div>
                <div className="pricing_01__content__calculator__best__plan__saving__box">
                  <div className="pricing_01__content__calculator__best__plan__title">
                    Estimated Savings
                  </div>
                  <div className="pricing_01__content__calculator__best__plan__saving">
                    ${numeral(bestPlan.savings).format('0,00')}/mo
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className="pricing_01__content__calculator__best__plan__title">
                    Best Plan
                  </div>
                  <div className="pricing_01__content__calculator__best__plan__name">
                    {bestPlan.title}
                    <br />
                    {bestPlan.subTitle}
                  </div>
                </div>
                <div>
                  <div className="pricing_01__content__calculator__best__plan__no__saving__box">
                    <div className="pricing_01__content__calculator__best__plan__no__saving__title">
                      Invest and save!
                    </div>
                  </div>
                  <div className="pricing_01__content__calculator__best__plan__saving">
                    ${numeral(bestPlan.totalCost).format('0,00.00')}
                  </div>
                  <div className="pricing_01__content__calculator__best__plan__title">
                    (per month)
                  </div>
                </div>
              </>
            ))}
            <a
              href={bestPlan.url}
              data-rewardful
              target="_blank"
              rel="noreferrer"
              className="pricing_01__content__calculator__best__plan__option__card"
            >
              <div className="pricing_01__content__calculator__plan__name">
                {bestPlan.buttonText}
              </div>
              <i className="fas fa-external-link-alt pricing_01__content__calculator__plan__icon highlight" />
            </a>
          </div>
        ) : (
          <div className="pricing_01__content__calculator__plan__list">
            <div className="pricing_01__content__calculator__plan__title">
              Base Plan Tiers
            </div>
            {refPlans.slice(0, 3).map((plan) => (
              <a
                href={plan.url}
                data-rewardful
                target="_blank"
                rel="noreferrer"
                className="pricing_01__content__calculator__plan__option__card"
              >
                <div className="pricing_01__content__calculator__plan__name">
                  {plan.title}
                </div>
                <i className="fas fa-external-link-alt pricing_01__content__calculator__plan__icon" />
              </a>
            ))}
          </div>
        )}
      </div>
    </div >
  );
}

export default Calculator;
