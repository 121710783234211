import React from "react";
import Faq01 from "src/components/faq/Faq01";
import Faq02 from "src/components/faq/Faq02";
import 'src/css/font.css';

function Faq() {
    return (
        <div>
            <Faq01 />
            <Faq02 />
        </div>
    );
}

export default Faq;