import React, { useEffect } from "react";
import './thankyou.css';
import { Reveal } from "../common/Reveal";

function Thankyou() {
    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'formSubmitted') {
                setTimeout(() => {
                    window.location.href = "/thankyou";
                }, 1000);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };

    }, []);

    return (
        <div className="thankyou_01">
            <div className="container">
                <div className="row">
                    <div className="thankyou_01__content">
                        <div className="thankyou_01__content__top">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="thankyou_01__content__title">
                                    THANK YOU FOR REACHING OUT TO US
                                </p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="thankyou_01__content__description">
                                    We have received your message and will get back to you shortly.
                                </p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="thankyou_01__content__description">
                                    Meanwhile, feel free to explore:
                                </p>
                            </Reveal>
                            <div className="thankyou_01__content__cta">
                                <a href="/allriseai" className="cta-btn">Our Product</a>
                                <a href="/pricing" className="cta-text">See Pricing</a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Thankyou;