import React from "react";
import './home.css';
import { Reveal } from "../common/Reveal";
import { motion } from "framer-motion";
import legal from "../../img/legal.webp";
import home from "../../img/home.webp";
import realestate from "../../img/realestate.webp";
import education from "../../img/education.webp";
import insurance from "../../img/insurance.webp";
import other from "../../img/other.webp";

function Home04() {
    // Accordion variables
    const [activeIndex, setActiveIndex] = React.useState(0);

    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }

    return (
        <div className="home_04">
            <div className="container">
                <div className="row">
                    <div className="home_04__content_top">
                        <div>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <h2 className="home_04__content_title">Seamless AI Integration</h2>
                            </Reveal>
                            <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="home_04__content_description">
                                    Do more with All Rise AI.
                                </p>
                            </Reveal>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="home_04__content_center">
                        <div className="home_04__card">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <i className="fa-regular fa-message fa-3x"></i>
                                <h3 className="home_04__card_title">
                                    Engagement
                                </h3>
                                <p className="home_04__card_description">
                                    Our AI agents contact interested individuals via calls and messages, engaging in lifelike conversations to ensure a positive experience for potential clients.
                                </p>
                            </Reveal>
                        </div>
                        <div className="home_04__card">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <i className="fas fa-list-check fa-3x"></i>
                                <h3 className="home_04__card_title">
                                    Qualification
                                </h3>
                                <p className="home_04__card_description">
                                    The AI assesses leads based on your criteria, engaging qualified ones and politely declining others, ensuring only high-potential leads are pursued to optimize resources.
                                </p>
                            </Reveal>
                        </div>
                        <div className="home_04__card">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <i className="far fa-paper-plane fa-3x"></i>
                                <h3 className="home_04__card_title">
                                    Seamless Delivery
                                </h3>
                                <p className="home_04__card_description">
                                    The process concludes with the AI handing over well-prepared, qualified leads to your sales team, enabling effective conversions based on the AI's comprehensive groundwork.
                                </p>
                            </Reveal>
                        </div>
                        <div className="home_04__card">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <i className="fas fa-network-wired fa-3x"></i>
                                <h3 className="home_04__card_title">
                                    Flexible Integration
                                </h3>
                                <p className="home_04__card_description">
                                    Our system adapts to deliver leads via live transfer, scheduled meetings, or other custom methods, ensuring smooth integration with your existing sales process.
                                </p>
                            </Reveal>
                        </div>
                        <div className="home_04__card">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <i className="fas fa-exchange-alt fa-3x"></i>
                                <h3 className="home_04__card_title">
                                    Customization and Training
                                </h3>
                                <p className="home_04__card_description">

                                    Each AI agent is custom-crafted and trained to meet your specific business needs, ensuring they function as a natural extension of your team, fully versed in your services.
                                </p>
                            </Reveal>
                        </div>
                        <div className="home_04__card">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <a href="/allriseai" className="home_04__card_link">Learn More</a>
                            </Reveal>
                        </div>
                    </div>
                </div >
                <div className="row">
                    <hr />
                    <div className="home_04__content_bottom">
                        <div>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <h2 className="home_04__content_title">Tailored for any industry</h2>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="home_04__content_description">
                                    From legal to real estate, All Rise AI is designed to enhance client interactions across a wide range of industries.
                                </p>
                            </Reveal>
                            <div className="home_04__content_bottom__accordion">
                                <div>
                                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                        <div className="home_04__content_bottom__accordion__header" onClick={() => setActiveIndex(0)}>
                                            <h3 className="home_04__content_bottom__accordion__title">Legal</h3>
                                            <i className={`fas fa-chevron-down ${activeIndex === 0 ? "active" : ""}`}></i>
                                        </div>
                                        <div className={`home_04__content_bottom__accordion__content ${activeIndex === 0 ? "active" : ""}`}>
                                            <p className="home_04__content_bottom__accordion__description">
                                                From Intake thru Case Work Up to Settlement Administration for Single Event, Mass Tort, and Class Actions.
                                            </p>
                                        </div>
                                    </Reveal>
                                </div>
                                <div>
                                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                        <div className="home_04__content_bottom__accordion__header" onClick={() => setActiveIndex(1)}>
                                            <h3 className="home_04__content_bottom__accordion__title">Home Services</h3>
                                            <i className={`fas fa-chevron-down ${activeIndex === 1 ? "active" : ""}`}></i>
                                        </div>
                                        <div className={`home_04__content_bottom__accordion__content ${activeIndex === 1 ? "active" : ""}`}>
                                            <p className="home_04__content_bottom__accordion__description">
                                                Solar, HVAC, Pest Control, Plumbing, Alarms, Windows, Landscaping, and much more.
                                            </p>
                                        </div>
                                    </Reveal>
                                </div>
                                <div>
                                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                        <div className="home_04__content_bottom__accordion__header" onClick={() => setActiveIndex(2)}>
                                            <h3 className="home_04__content_bottom__accordion__title">Financial & Real Estate</h3>
                                            <i className={`fas fa-chevron-down ${activeIndex === 2 ? "active" : ""}`}></i>
                                        </div>
                                        <div className={`home_04__content_bottom__accordion__content ${activeIndex === 2 ? "active" : ""}`}>
                                            <p className="home_04__content_bottom__accordion__description">
                                                Debt, Tax, Credit Repair, Real Estate, Mortgage, etc.
                                            </p>
                                        </div>
                                    </Reveal>
                                </div>
                                <div>
                                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                        <div className="home_04__content_bottom__accordion__header" onClick={() => setActiveIndex(3)}>
                                            <h3 className="home_04__content_bottom__accordion__title">Education & Auto</h3>
                                            <i className={`fas fa-chevron-down ${activeIndex === 3 ? "active" : ""}`}></i>
                                        </div>
                                        <div className={`home_04__content_bottom__accordion__content ${activeIndex === 3 ? "active" : ""}`}>
                                            <p className="home_04__content_bottom__accordion__description">
                                                EDU, Warranties, and Service Appointment Bookings to name a few.
                                            </p>
                                        </div>
                                    </Reveal>
                                </div>
                                <div>
                                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                        <div className="home_04__content_bottom__accordion__header" onClick={() => setActiveIndex(4)}>
                                            <h3 className="home_04__content_bottom__accordion__title">Insurance</h3>
                                            <i className={`fas fa-chevron-down ${activeIndex === 4 ? "active" : ""}`}></i>
                                        </div>
                                        <div className={`home_04__content_bottom__accordion__content ${activeIndex === 4 ? "active" : ""}`}>
                                            <p className="home_04__content_bottom__accordion__description">
                                                From Life, Auto, and Home to Health, Medicare, ACA, SSDI and more.
                                            </p>
                                        </div>
                                    </Reveal>
                                </div>
                                <div>
                                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                                        <div className="home_04__content_bottom__accordion__header" onClick={() => setActiveIndex(5)}>
                                            <h3 className="home_04__content_bottom__accordion__title">Other Verticals</h3>
                                            <i className={`fas fa-chevron-down ${activeIndex === 5 ? "active" : ""}`}></i>
                                        </div>
                                        <div className={`home_04__content_bottom__accordion__content ${activeIndex === 5 ? "active" : ""}`}>
                                            <p className="home_04__content_bottom__accordion__description">
                                                Not enough room to name them all. Contact us to discuss.
                                            </p>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>
                        </div>
                        <div className="home_04__content_bottom_img_container">
                            <motion.img src={legal} alt="Placeholder" variants={{
                                hidden: hidden1,
                                visible: visible1
                            }} initial="hidden" animate={activeIndex === 0 ? "visible" : "hidden"} style={{display: activeIndex === 0 ? "block" : "none"}} />
                            <motion.img src={home} alt="Placeholder" variants={{
                                hidden: hidden1,
                                visible: visible1
                            }} initial="hidden" animate={activeIndex === 1 ? "visible" : "hidden"} style={{display: activeIndex === 1 ? "block" : "none"}} />
                            <motion.img src={realestate} alt="Placeholder" variants={{
                                hidden: hidden1,
                                visible: visible1
                            }} initial="hidden" animate={activeIndex === 2 ? "visible" : "hidden"} style={{display: activeIndex === 2 ? "block" : "none"}} />
                            <motion.img src={education} alt="Placeholder" variants={{
                                hidden: hidden1,
                                visible: visible1
                            }} initial="hidden" animate={activeIndex === 3 ? "visible" : "hidden"} style={{display: activeIndex === 3 ? "block" : "none"}} />
                            <motion.img src={insurance} alt="Placeholder" variants={{
                                hidden: hidden1,
                                visible: visible1
                            }} initial="hidden" animate={activeIndex === 4 ? "visible" : "hidden"} style={{display: activeIndex === 4 ? "block" : "none"}} />
                            <motion.img src={other} alt="Placeholder" variants={{
                                hidden: hidden1,
                                visible: visible1
                            }} initial="hidden" animate={activeIndex === 5 ? "visible" : "hidden"} style={{display: activeIndex === 5 ? "block" : "none"}} />
                            </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Home04;