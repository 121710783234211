import React from "react";
import './pricing.css';

function FeatureRow(props) {
    return (
        <tr>
            <td className="pricing_01__content__bottom__card">
                <p className="pricing_01__content__bottom__card__title">{props.title}</p>
            </td>
            <td className="pricing_01__content__bottom__card">
                <p className={props.firstCheck === "check" ? "pricing_01__content__bottom__card__title bg-check" : props.firstCheck === "times" ? "pricing_01__content__bottom__card__title bg-times" : "pricing_01__content__bottom__card__title"}>
                    {props.firstCheck === "check" ? <span><i className="fa fa-check"></i>{props.first}</span> : ""}
                    {props.firstCheck === "times" ? <span><i className="fa fa-times"></i>{props.first}</span> : ""}
                    {props.firstCheck !== "check" && props.first !== "times" ? props.first : ""}

                </p>
            </td>
            <td className="pricing_01__content__bottom__card">
                <p className={props.secondCheck === "check" ? "pricing_01__content__bottom__card__title bg-check" : props.secondCheck === "times" ? "pricing_01__content__bottom__card__title bg-times" : "pricing_01__content__bottom__card__title"}>
                    {props.secondCheck === "check" ? <span><i className="fa fa-check"></i>{props.second}</span> : ""}
                    {props.secondCheck === "times" ? <span><i className="fa fa-times"></i>{props.second}</span> : ""}
                    {props.secondCheck !== "check" && props.second !== "times" ? props.second : ""}
                </p>
            </td>
            <td className="pricing_01__content__bottom__card">
                <p className={props.thirdCheck === "check" ? "pricing_01__content__bottom__card__title bg-check" : props.thirdCheck === "times" ? "pricing_01__content__bottom__card__title bg-times" : "pricing_01__content__bottom__card__title"}>
                    {props.thirdCheck === "check" ? <span><i className="fa fa-check"></i>{props.third}</span> : ""}
                    {props.thirdCheck === "times" ? <span><i className="fa fa-times"></i>{props.third}</span> : ""}
                    {props.thirdCheck !== "check" && props.third !== "times" ? props.third : ""}
                </p>
            </td>
            <td className="pricing_01__content__bottom__card">
                <p className={props.fourthCheck === "check" ? "pricing_01__content__bottom__card__title bg-check" : props.fourthCheck === "times" ? "pricing_01__content__bottom__card__title bg-times" : "pricing_01__content__bottom__card__title"}>
                    {props.fourthCheck === "check" ? <span><i className="fa fa-check"></i>{props.fourth}</span> : ""}
                    {props.fourthCheck === "times" ? <span><i className="fa fa-times"></i>{props.fourth}</span> : ""}
                    {props.fourthCheck !== "check" && props.fourth !== "times" ? props.fourth : ""}
                </p>
            </td>
        </tr>
    );
}

export default FeatureRow;