import React, { useEffect } from "react";
import './home.css';

const useAudio = (url) => {
    const [audio] = React.useState(new Audio(url));
    const [playing, setPlaying] = React.useState(false);

    const toggle = () => {
        // if any  audio is playing, stop it, even in other components
        let audios = document.getElementsByTagName('audio');
        for (let i = 0; i < audios.length; i++) {
            audios[i].pause();
        }
        setPlaying(!playing);


    };

    useEffect(() => {
        playing ? audio.play() : audio.pause();

    }, [playing, audio]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audio]);

    return [playing, toggle];
};

function AudioBtn(props) {
    const [playing, toggle] = useAudio(props.url);

    return (
        <button
            onClick={() => {
                toggle();
            }}
            className="home_01_content_try_card">
            <div className="home_01_content_try_card_icon">
                <i className="fas fa-phone"></i>
            </div>
            <div>
                <span className="cta-text">{props.assistantName}</span>
                <small>{
                    (!playing) ? `Listen Now` : "Listening - Click to end"
                }</small>
            </div>
        </button>

    );
}

export default AudioBtn;