import React from "react";
import './faq.css';

function Faq02() {
    // Accordion variables
    const [activeIndex, setActiveIndex] = React.useState(0);
    const faqs = [
        {
            question: "What is All Rise AI?",
            answer: "All Rise AI is an advanced artificial intelligence system that automates the intake process across various industries. It facilitates interactions such as calls, SMS, and emails, and assesses consumer eligibility for services or claims, transferring eligible cases to appropriate centers.",
        },
        {
            question: "How does All Rise AI determine eligibility?",
            answer: "All Rise AI uses predetermined criteria tailored to industry standards to evaluate eligibility. It involves posing essential qualification questions and utilizing an integrated knowledge base to analyze responses accurately.",
        },
        {
            question: "Can All Rise AI handle both inbound and outbound interactions?",
            answer: "Yes, All Rise AI is equipped to manage both inbound and outbound interactions effectively. This includes compliance measures to prevent potential legal issues during outbound engagements.",
        },
        {
            question: "What industries can benefit from All Rise AI?",
            answer: "Initially developed for the legal sector, All Rise AI is versatile and can be adapted for healthcare, insurance, customer service, and any industry requiring eligibility assessment or intake processing.",
        },
        {
            question: "What happens if a consumer does not qualify?",
            answer: "If a consumer does not meet the necessary eligibility criteria, All Rise AI courteously informs them, ensuring a professional interaction that maintains consumer trust and satisfaction.",
        },
        {
            question: "How does All Rise AI improve operational efficiency?",
            answer: "All Rise AI automates the initial stages of client interactions and assessments, significantly reducing the need for human intervention. This automation decreases labor costs, minimizes errors, and enhances the speed and accuracy of client service processes.",
        },
        {
            question: "Is human oversight involved in the All Rise AI process?",
            answer: "Yes, despite the high level of automation, human oversight remains crucial, especially in situations or industries where personal judgment is essential. All Rise AI is designed to complement human operators, not replace them.",
        },
        {
            question: "How secure is All Rise AI with sensitive information?",
            answer: "All Rise AI complies with stringent data protection and privacy regulations to secure sensitive information. It employs advanced security protocols to prevent unauthorized access and ensure data integrity.",
        },
        {
            question: "Can All Rise AI be customized for specific business needs?",
            answer: "Yes, All Rise AI offers extensive customization options to match specific business requirements and workflows, allowing companies to tailor the AI’s settings to their operational needs and client handling procedures.",
        },
        {
            question: "What future enhancements are planned for All Rise AI?",
            answer: "Future enhancements for All Rise AI include further capabilities for handling more complex interactions, deeper integration with client systems, and continuous updates to the AI’s knowledge base to improve decision accuracy and adapt to evolving regulations or business practices.",
        },
        {
            question: "Is it legal to make outbound calls with AI?",
            answer: "Short Answer is Yes.  For more details read the FCC Release from February 8th, 2024.:",
            link: "https://www.fcc.gov/document/fcc-makes-ai-generated-voices-robocalls-illegal"
        },
        {
            question: "Is it HIPAA compliant to use AI in speaking with consumers about sensitive data?",
            answer: "All Rise AI offers a HIPAA compliant option for applicable industries."
        }
    ];

    const faqList = faqs.map((faq, index) => {
        return (
            <div key={index}>
                <div className="faq_02__content_bottom__accordion__header" onClick={() => setActiveIndex(index)}>
                    <h3 className="faq_02__content_bottom__accordion__title">{faq.question}</h3>
                    <i className={`fas fa-chevron-down ${activeIndex === index ? "active" : ""}`}></i>
                </div>
                <div className={`faq_02__content_bottom__accordion__content ${activeIndex === index ? "active" : ""}`}>
                    <p className="faq_02__content_bottom__accordion__description">
                        {faq.answer}
                        {faq.link && <a href
                            ={faq.link} target="_blank" rel="noreferrer"> {faq.link}
                            &nbsp;<i className="fas fa-external-link-alt fa-lg"></i>
                            </a>}
                    </p>
                </div>
            </div>
        );
    });

    return (
        <div className="faq_02">
            <div className="container">
                <div className="row">
                    <div className="faq_02__content_bottom">
                        <div className="faq_02__content_bottom_txt_container">
                            <span>

                                ALL RISE AI
                            </span>
                        </div>
                        <div>
                            <div className="faq_02__content_bottom__accordion">
                                {faqList}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq02;