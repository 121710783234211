import React from "react";
import './home.css';

function Home03() {
    return (
        <div className="home_03">
            {/* <div className="container">
                <div className="row">
                    <div className="home_03__content">
                        Here goes the demo
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Home03;