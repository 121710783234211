import React from "react";
import './home.css';
import { Reveal } from "../common/Reveal";
// import CallBtn from "./CallBtn";
import AudioBtn from "./AudioBtn";
import Carrousel from "./Carrousel";
import HomeAudio from "../../audio/home.wav";
import LegalAudio from "../../audio/legal.mp3";
import InsuranceAudio from "../../audio/insurance.mp3";
import FinancialAudio from "../../audio/financial.mp3";

function Home01() {
  const hidden1 = {
    opacity: 0,
    y: 75
  }
  const visible1 = {
    opacity: 1,
    y: 0
  }

  const hidden2 = {
    left: 0
  }

  const visible2 = {
    left: "100%"
  }
  return (
    <>
      <div className="home_01">
        <div className="container">
          <div className="row">
            <div className="home_01__content">
              <div className="home_01__content__top">
                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                  <p className="home_01__content__title bebas">Transforming Client Interactions with AI</p>
                </Reveal>
                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                  <p className="home_01__content__description">Explore how our AI-driven Contact Center and Lead Management System revolutionize client communications across industries.</p>
                </Reveal>
                <br />
                <div className="home_01_content_try_container">
                  <div className="home_01_content_try_title_container">
                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                      <center><p className="home_01_content_try_title bebas" >
                        WHY BLEND IN? STAND OUT WITH PREMIUM AI VOICE AGENTS FROM ALL RISE AI. LISTEN TO LIVE CALLS!
                      </p></center>
                    </Reveal>

                  </div>
                  <div className="home_01_content_try_cards" style={{ flexWrap: "wrap", justifyContent: "space-evenly", padding: "40px 0" }}>

                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                      <AudioBtn url={HomeAudio} assistantName="HOME SERVICES" />

                    </Reveal>
                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                      <AudioBtn url={LegalAudio} assistantName="LEGAL SERVICES" />
                    </Reveal>
                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                      <AudioBtn url={FinancialAudio} assistantName="FINANCIAL SERVICES" />
                    </Reveal>
                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                      <AudioBtn url={InsuranceAudio} assistantName="INSURANCE SERVICES" />
                    </Reveal>
                  </div>
                </div>
                {/* <div className="home_01_content_try_container">
                                <div className="home_01_content_try_title_container">
                                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    <center><p className="home_01_content_try_title bebas" >
                                            WHY BLEND IN? STAND OUT WITH PREMIUM AI VOICE AGENTS FROM ALL RISE AI. TRY TODAY!
                                        </p></center>
                                    </Reveal>

                                </div>
                                <div className="home_01_content_try_cards" style={{ flexWrap: "wrap", justifyContent: "space-evenly", padding: "40px 0" }}>

                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <CallBtn assistantId="889047a8-3600-47f1-81e7-c5e3652ac707" assistantName="ROOFING DEMO" />
                                    </Reveal>
                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <CallBtn assistantId="0af2106f-0cdb-40af-91e9-adcaabc9bc4e" assistantName="LEGAL DEMO" />
                                    </Reveal>
                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <CallBtn assistantId="2eac7ec5-39ba-4278-bf98-bbe03ccfd2a9" assistantName="REAL ESTATE DEMO" />
                                    </Reveal>
                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <CallBtn assistantId="8dc23abe-79d7-4682-9c08-2c6c81376824" assistantName="FORECLOSURE DEMO" />
                                    </Reveal>
                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <CallBtn assistantId="a381a8a7-fdfa-426f-90b6-58871deb5e3c" assistantName="OZEMPIC DEMO" />
                                    </Reveal>
                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <CallBtn assistantId="c2fcc99d-f39f-40f2-af1a-94c243b79405" assistantName="MVA DEMO" />
                                    </Reveal>
                                </div>
                            </div> */}
                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                  <div className="home_01__content__cta">
                    <a href="/contact" className="cta-btn">Discover How</a>
                    <a href="/pricing" className="cta-text">See Pricing</a>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Carrousel />
    </>
  );
}

export default Home01;