import React from "react";
import ThankyouCheckout from "src/components/thankyou/ThankyouCheckout";
import 'src/css/font.css';

function ThankyouC() {
    return (
        <div>
            <ThankyouCheckout />
        </div>
    );
}

export default ThankyouC;