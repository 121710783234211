import React from "react";
import './home.css';
import { Reveal } from "../common/Reveal";

function Home02() {

    const hidden1 = {
        opacity: 0,
        y: "100%"
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: "100%"
    }

    const visible2 = {
        left: "100%"
    }
    return (
        <div className="home_02">
            <div className="container">
                <div className="row">
                    <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>

                        <div className="home_02__content">
                            <div className="home_02__content__center">
                                <p className="home_02__content__title">Enhance Every Interaction</p>
                                <div className="home_02__content__metrics">
                                    <div className="home_02__content__metric">
                                        <p className="home_02__content__metric__number">
                                            &infin;
                                        </p>
                                        <p className="home_02__content__metric__description">Number of Agents</p>
                                    </div>
                                    <div className="home_02__content__metric">
                                        <p className="home_02__content__metric__number">
                                            +1000
                                        </p>
                                        <p className="home_02__content__metric__description">Successful Campaigns</p>
                                    </div>
                                    <div className="home_02__content__metric">
                                        <p className="home_02__content__metric__number">70+</p>
                                        <p className="home_02__content__metric__description">Integrations Across Platforms</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>
        </div>
    );
}

export default Home02;