import React from "react";
import './footer.css';
import logo from "src/img/logo-white.png";

function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer__container">
                        <div className="footer__logo">
                            <img src={logo} alt="arai-logo" />
                        </div>
                        {/* <div className="footer__contact">
                            <h3>Contact</h3>

                            <p>
                                <a href="tel:" className="footer__phone">
                                    test
                                </a>
                            </p>
                        </div> */}
                        <div className="footer__menu">
                            <ul>
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="/allriseai">Our Product</a>
                                </li>
                                <li>
                                    <a href="/pricing">Pricing</a>
                                </li>
                                <li>
                                    <a href="/faq">FAQ</a>
                                </li>
                                <li>
                                    <a href="/contact">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="footer__copy">
                        <hr />
                        <div className="footer__copy_text">
                            <p>&copy; 2024 All Rise Ai</p>
                            <a href="/privacy">Privacy Policy</a>
                            <a href="/terms">Terms of Service</a>
                            <a href="/disclaimer">Disclaimer</a>
                            {/*
                            eslint-disable
                        */}
                            <a href="#" className="termly-display-preferences">Consent Preferences</a>
                            <a href="https://app.termly.io/notify/31f12cce-c086-48cc-a6dd-61ef6ac7967b" target="_blank" rel="noreferrer">
                                Do Not Sell or Share My Personal information
                            </a>
                            <p>All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;