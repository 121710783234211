import React from "react";
import ReferralContact01 from "src/components/referralcontact/ReferralContact01";
import 'src/css/font.css';

function ReferralContact() {
    return (
        <div>
            <ReferralContact01 />
        </div>
    );
}

export default ReferralContact;