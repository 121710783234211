import React from "react";
import '../css/App.css';
import '../css/font.css';
import PrivacyComponent from "src/components/old/legal/privacy";


function Privacy() {
    return (
        <div>
            <PrivacyComponent />
        </div>
    );
}

export default Privacy;