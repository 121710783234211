import React, { useEffect } from "react";
import './contact.css';
import { Reveal } from "../common/Reveal";

function Contact01() {
    const [token, setToken] = React.useState("");
    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.status === 'formSubmitted') {
                setTimeout(() => {
                    window.location.href = "/thankyou?email=" + event.data.email;
                }, 1000);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };

    }, []);

    useEffect(() => {
        // wait for rewardful to load
        const interval = setInterval(() => {
            if (window.Rewardful) {
                // eslint-disable-next-line
                setToken(Rewardful.affiliate.token);
                clearInterval(interval);
            }
        }, 1000);
    }, []);

    return (
        <div className="contact_01">
            <div className="container">
                <div className="row">
                    <div className="contact_01__content">
                        <div className="contact_01__content__top">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="contact_01__content__title">
                                    BEGIN YOUR JOURNEY WITH <b className="highlight">ALL RISE AI</b>
                                </p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="contact_01__content__description">
                                    Take the first step towards redefining your lead management and intake process.
                                </p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>

                                <p className="contact_01__content__description">

                                    Sign up for our free demo today and discover how our comprehensive AI-powered solution can help your business reduce costs, improve efficiency, and maintain the highest standards of quality and consistency, even as you scale.
                                </p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>

                                <p className="contact_01__content__description">

                                    Embrace the future of customer engagement with our advanced, cost-effective technology designed to keep you ahead in a competitive landscape.
                                </p>
                            </Reveal>

                        </div>
                        <div className="contact_01__content__bottom">
                            <iframe
                                src={"https://link.allriseai.com/widget/form/wvcIikRUQoHI23nfzeKo?referral=" + token}
                                data-rewardful
                                style={{ width: "100%", minHeight: "1000px", border: "none", "borderRadius": "3px", "marginTop": "30px" }}
                                id="inline-ilP2oXj9qr1NuHBJ4Og7"
                                data-layout="{'id':'INLINE'}"
                                data-trigger-type="alwaysShow"
                                data-trigger-value=""
                                data-activation-type="alwaysActivated"
                                data-activation-value=""
                                data-deactivation-type="neverDeactivate"
                                data-deactivation-value=""
                                data-form-name="AllRise AI Demo Form"
                                data-height="947"
                                data-layout-iframe-id="inline-ilP2oXj9qr1NuHBJ4Og7"
                                data-form-id="ilP2oXj9qr1NuHBJ4Og7"
                                title="AllRise AI Demo Form"
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact01;