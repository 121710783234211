import React from "react";
import './product.css';
import { Reveal } from "../common/Reveal";

function Product02() {
    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }
    return (
        <div className="product_02">
            <div className="container">
                <div className="row">
                    <div className="product_02__content">
                        <div className="product_02__content__center">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="product_02__content__title">Tailored to Your Needs</p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <a href="/contact" className="cta-btn">Customize Your Solution</a>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product02;