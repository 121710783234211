export const cards = [
  {
    title: "Outbound Case Study: AI 4X’s Outbound Conversions for BPO",
    challenge: "Companies across various industries relied on human agents for inbound call handling, lead qualification, and live transfers, leading to inefficiencies like missed calls, varied accuracy, and high overhead costs.",
    solution: "The AI solution was implemented across multiple industries, replacing human agents entirely in the qualification and live transfer process. Operating 24/7/365, the AI answered every call, asked qualifying questions, and transferred qualified leads while rejecting those that didn’t meet the criteria. Each company received a dedicated phone number, with calls routed directly to the AI, ensuring no lead was missed.",
    implementation: "The average setup time was 6 days, and the AI seamlessly adapted to different business models and verticals, handling them efficiently.",
    results: [
      "3-5X Increase in Live Transfers: AI's consistent availability ensured no missed calls.",
      "2X Increase in Accuracy: The AI followed exact qualification criteria, improving accuracy.",
      "40% Reduction in Call Time: Streamlined processes led to quicker handling and transfers."
    ],
  },
  {
    title: "Outbound Case Study: Outbound AI Qualifying and Live Transferring to Buyers",
    challenge: "Companies in legal, debt, and insurance sectors faced inefficiencies and inconsistencies in their lead qualification processes. Human agents were responsible for outbound dialing, qualifying leads with 6 to 12 questions, and live transferring calls to closers. This manual approach led to varied outcomes, increased operational costs, and delayed call handling times.",
    solution: "Our AI solution replaced human agents entirely in the outbound dialing, qualifying, and live transfer process. By integrating with existing systems through API calls, the AI handled calls without being classified as an Automated Telephone Dialing System (ATDS) under FCC regulations.",
    implementation: "The setup process was quick, with full implementation in just 7 days.",
    results: [
      "50% Increase in Transferred Calls: The AI significantly boosted the number of calls transferred, enhancing operational efficiency.",
      "65% Reduction in Costs: Automation of the qualification process led to a 65% reduction in costs.",
      "45% Reduction in Average Call Time: The AI streamlined the process, cutting call times and improving productivity.",
      "Improved Consistency: The AI consistently followed optimized criteria, eliminating human error and ensuring high-quality leads.",
      "Enhanced Efficiency: Automating dialing and qualification reduced downtime, allowing faster and more effective lead processing, enabling companies to scale without additional human resources."
    ],
  },
  {
    title: "Outbound Case Study: AI Increasing Conversions Through SMS and Voicemail",
    challenge: "Two companies in SSDI and EDU sectors were already successfully using an AI solution for outbound calling. However, there was an opportunity to further boost conversion rates by enhancing the follow-up process for unanswered calls. Adding SMS and voicemail follow-ups aimed to re-engage leads who didn’t initially respond.",
    solution: "To address this, the AI solution was enhanced with SMS and voicemail follow-up features. The AI sent SMS messages that offered leads two options: a callback link or a direct link to schedule an appointment. For those preferring voicemail, the AI left messages prompting them to call back and continue the qualification process. These additional touchpoints ensured leads had multiple opportunities to engage, boosting conversion rates.",
    implementation: "The setup process for adding SMS and voicemail follow-up features took 14 days, mainly due to ATP registration delays. Once the registration was complete, the AI was seamlessly integrated into the clients' systems and began handling follow-ups tailored to each client's needs.",
    results: [
      "28% Increase in Conversions via SMS: The SMS follow-ups provided flexible re-engagement options, significantly boosting conversions.",
      "17% Increase in Conversions via Voicemail: Voicemail follow-ups added another channel for engagement, further increasing conversions.",
      "Improved Lead Engagement: The new follow-up features greatly enhanced lead engagement, capturing more conversions from previously unresponsive leads.",
      "Seamless Integration: The AI’s consistent follow-up strategy minimized missed opportunities by ensuring every lead was contacted multiple times."
    ],
  },
  {
    title: "Outbound Case Study: Appointment Scheduling with Outbound AI",
    challenge: "An ERC Tax Credit provider and two Personal Injury Law Firms faced challenges in scaling their outbound dialing processes. Human agents were making outbound calls, qualifying leads, and scheduling appointments, but this approach was time-consuming and costly. The companies needed a more efficient solution to handle high call volumes, qualify leads, and schedule appointments without relying on human agents.",
    solution: "The AI solution was implemented to fully automate outbound dialing, lead qualification, and appointment scheduling. The AI managed outbound calls, asked key qualification questions, and booked appointments for qualified leads, freeing human agents to focus on high-value tasks.",
    implementation: "The integration process for the AI to handle outbound dialing, lead qualification, and appointment scheduling took 10 days. Once operational, the AI managed the entire process, ensuring no leads were missed and appointments were consistently scheduled.",
    results: [
      "61% Increase in Scheduled Appointments: The AI significantly boosted the number of scheduled appointments, reducing the chances of losing potential clients.",
      "72% Reduction in Costs: Automating the outbound process led to a 72% reduction in costs by removing the need for human agents in these tasks.",
      "47% Reduction in Average Call Time: The AI’s streamlined approach decreased call times, allowing more leads to be processed efficiently.",
      "Improved Consistency: The AI handled each call with accuracy, adhering to set criteria and eliminating human inconsistencies in lead qualification and scheduling.",
      "Increased Efficiency: Automation allowed companies to scale operations without additional staffing, with the AI working 24/7 to engage every potential lead."
    ],
  },
  {
    title: "Inbound Case Study: AI Qualifies and Live Transfers",
    challenge: "Multiple companies across various industries faced inefficiencies in handling inbound calls with human agents who qualified leads and live-transferred them to closers or buyers. This process was plagued by missed calls due to limited staffing, variable accuracy, and high overhead costs associated with call centers. A solution was needed to ensure every inbound call was efficiently answered, qualified, and transferred without human errors or costs.",
    solution: "The AI solution replaced human agents entirely in the inbound call qualification and live transfer process. Operating 24/7/365, the AI answered calls, asked qualifying questions, and live-transferred qualified leads while declining those that didn’t meet criteria. Each company received a dedicated phone number routed directly to the AI, ensuring no lead was missed.",
    implementation: "The average setup time was 6 days. The AI adapted seamlessly to various business models, managing multiple verticals without issue.",
    results: [
      "3-5X Increase in Live Transfers: AI's consistent availability and performance led to a significant boost in live transfers.",
      "2X Increase in Accuracy: By adhering strictly to qualification criteria, the AI doubled the accuracy of lead transfers compared to human agents.",
      "40% Reduction in Call Time: Streamlining the qualification process reduced average call times, enhancing efficiency."
    ],
  },
  {
    title: "Inbound Case Study: Many to Many Custom AI Inbound Solution",
    challenge: "Results Calls, led by Toby Macaro, managed inbound calls from multiple publishers and sold qualified calls to multiple buyers in the home services sector. With human agents handling 1,200 calls daily, only 5% converted into paid transfers. The complexity of tracking calls from publishers to buyers led to missed opportunities and inefficiencies, prompting a need for a scalable, automated solution to improve call conversions and streamline the tracking process.",
    solution: "The AI solution automated the entire call qualification and live transfer process, complemented by a custom tool that tracked calls from publishers to buyers, ensuring transparency and efficient routing. The AI qualified calls based on specific criteria and transferred them seamlessly to buyers, removing human intervention and maximizing revenue potential.",
    implementation: "The AI and custom tracking tool were integrated with Results Calls' existing systems in 14 days. Initially implemented for the roofing industry, the solution was designed to handle complex multi-publisher and multi-buyer scenarios.",
    results: [
      "10X Increase in Conversions: Conversions increased from 5% to 50% in the first week, significantly boosting paid transfers and revenue.",
      "Scalability Across Verticals: Initially used for roofing, the AI solution expanded to eight additional home services verticals, including plumbing, pest control, appliances, and electrical.",
      "Improved Tracking and Efficiency: The custom tool ensured transparent tracking of calls, maximizing the value of each qualified transfer.",
      "Cost Efficiency: The AI reduced the cost per call to an average of 24 cents, billing only for active conversation time, ensuring precise cost management."
    ],
  },
  {
    title: "Inbound Case Study: AI Completely Replaces Inbound Call Center",
    challenge: "The client, a legal industry marketer, struggled with operational challenges and high costs due to reliance on human agents to handle inbound calls 24/7. Inconsistencies in agent performance and the need for multiple call centers across the nation created inefficiencies and increased expenses.",
    solution: "The AI solution was implemented to replace human agents, handling the entire call process—gathering caller information and case details efficiently. Operating 24/7/365, the AI ensures no calls are missed and delivers leads to attorneys with consistent quality, ultimately allowing the client to close their call centers.",
    implementation: "The AI setup took 6 days, after which it began managing calls, collecting details, and routing information to attorneys. The transition provided a seamless switch from human to AI-driven call handling.",
    results: [
      "50% Reduction in Call Time: The AI reduced average call times from 6 minutes to 3 minutes, gathering information more efficiently than human agents.",
      "80% Cost Reduction: The company plans to close all call centers, cutting costs by 80% and eliminating the need for multiple physical locations and inconsistent human agents.",
      "Improved Lead Quality: The AI’s strict qualification process has enhanced lead quality, increasing the value of each lead and boosting conversion rates for attorneys."
    ],
  },
  {
    title: "Inbound Case Study: AI Inbound Qualifying and Appointment Scheduling",
    challenge: "A tax professional firm specializing in negotiating IRS tax liens faced inefficiencies, with human professionals spending 5-6 hours daily on unqualified calls. This left limited time for working on qualified leads, necessitating a solution to improve initial call qualifications and optimize appointment scheduling.",
    solution: "The AI solution was implemented to qualify inbound calls and schedule appointments directly on the tax professionals’ calendars. The AI determined caller eligibility through a series of questions, scheduling only qualified leads. To enhance attendance, SMS reminders were sent to consumers, ensuring a high show rate.",
    implementation: "The AI setup took 14 days due to ATP registration with the carrier. Once integrated, the AI managed inbound calls, qualified leads, and scheduled appointments seamlessly.",
    results: [
      "65% Increase in Human Resources Efficiency: The AI allowed tax professionals to focus solely on qualified leads, boosting productivity by 65%.",
      "90%+ Show Rate: SMS reminders led to a show rate of over 90%, maximizing appointment attendance.",
      "Fully Booked Calendars: Tax professionals shifted from spending hours on unqualified calls to having their calendars filled with valuable, qualified appointments."
    ],
  },
];