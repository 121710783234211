import React, { useEffect } from "react";
import './referral.css';
import { Reveal } from "../common/Reveal";

function Referral() {
    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'formSubmitted') {
                setTimeout(() => {
                    window.location.href = "/referral";
                }, 1000);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };

    }, []);

    return (
        <div className="referral_01">
            <div>
                <div className="container">
                    <div className="row">
                        <div className="referral_01__content">
                            <div className="referral_01__content__left">
                                <div>
                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <p className="referral_01__content__title">
                                            Lifelong Partners Program
                                        </p>
                                    </Reveal>
                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <p className="referral_01__content__info">
                                            Join our referral program and become a vital part of our growing community. We pay a commission for each active client you refer us. 10% for the first 12 months converting to 5% for life thereafter. It's our way of saying thank you for helping us expand our reach and strengthen our community of dedicated users. Start sharing and earning today!
                                        </p>
                                    </Reveal>
                                </div>



                            </div>
                            <div className="referral_01__content__right">
                                <div className="referral_01__content__form">
                                    <a href="/referral-signup" className="btn btn-primary" >CLICK HERE TO JOIN</a>
                                    {/* <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <p className="form-terms">
                                            <b>* The following Terms apply</b>: Commissions are paid for as long as the referred client remains active with All Rise AI. If a referred client cancels or pauses their service, commission payments will be suspended during the inactive period. Any disputes regarding referrals and commissions will be handled by our support team, with decisions made at the discretion of All Rise AI management.
                                        </p>
                                    </Reveal> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row">
                        <div className="referral_01__content">
                            <div className="referral_01__content__left">
                                <div>
                                    <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                        <p className="referral_01__content__info">
                                            <i className="fas fa-globe"></i>
                                            <p>
                                                <b> Who Can Join</b>: Anyone interested is eligible to participate in the referral program.
                                            </p>
                                        </p>
                                        <p className="referral_01__content__info">
                                            <i className="fas fa-handshake"></i>
                                            <p>
                                                <b> How to Join</b>: Sign up through the "All Rise AI – Referral Program" tab on our website to receive a unique tracking link for new client sign-ups.
                                            </p>
                                        </p>
                                        <p className="referral_01__content__info">
                                            <i className="fas fa-money-bill-wave"></i>
                                            <p>
                                                <b> Commission Details</b>: Earn a 10% for the first 12 months and 5% thereafter for ongoing commission on subscription fees and minutes used by referred clients, with monthly payments via ACH or wire, and track all activity through a real-time dashboard on the All Rise AI platform.
                                            </p>
                                        </p>
                                    </Reveal>
                                </div>


                            </div>
                            <div className="referral_01__content__right">

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row">
                        <div className="referral_01__content">
                            <div className="referral_01__content__form">
                                <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    <a href="/referral-signup" className="btn btn-primary" >CLICK HERE TO JOIN</a>
                                </Reveal>
                                {/* <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                    <p className="form-terms">
                                        <b>* The following Terms apply</b>: Commissions are paid for as long as the referred client remains active with All Rise AI. If a referred client cancels or pauses their service, commission payments will be suspended during the inactive period. Any disputes regarding referrals and commissions will be handled by our support team, with decisions made at the discretion of All Rise AI management.
                                    </p>
                                </Reveal> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Referral;