import React, { useEffect } from "react";
import "./legal.css";

function TermsComponent(props) {

    useEffect(() => {
        var js, tjs = document.getElementsByTagName("script")[0];
        if (document.getElementById("termly-jssdk")) return;
        js = document.createElement("script");
        js.id = "termly-jssdk";
        js.src = "https://app.termly.io/embed-policy.min.js";
        tjs.parentNode.insertBefore(js, tjs);
    }
    );

    return (
        <div id="landing" className="show">

            <div className="legal-container">
                <div className="legal-section">
                    <div className="legal-section-title">
                        <h1>Terms & Conditions</h1>
                    </div>
                    <div className="legal-section-content">
                        <div name="termly-embed" data-id="1c17207a-efd1-413b-9f67-e8094d37a8fc"></div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default TermsComponent;
