import React from "react";
import ReferralForm from "src/components/ReferralProgram/ReferralForm";
import 'src/css/font.css';

function RefForm() {
    return (
        <div>
            <ReferralForm />
        </div>
    );
}

export default RefForm;