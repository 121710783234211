import React from "react";
import './home.css';
import azure from "src/img/azure.svg";
import calendly from "src/img/calendly.png";
import ghl from "src/img/ghl.png"
import googleCalendar from "src/img/googleCalendar.png"
import hubspot from "src/img/hubspot.webp"
import make from "src/img/make.svg"
import outlook from "src/img/outlook.png"
import pipedrive from "src/img/pipedrive.png"
import ringba from "src/img/ringba.png"
import salesforce from "src/img/salesforce.png"
import twilio from "src/img/twilio.png"
import vonage from "src/img/vonage.png"
import zoom from "src/img/zoom.png"

function Carrousel() {

  const logos = [
    { width: 75, url: googleCalendar },
    { width: 160, url: hubspot },
    { width: 120, url: outlook },
    { width: 200, url: vonage },
    { width: 180, url: pipedrive },
    { width: 250, url: azure },
    { width: 200, url: ringba },
    { width: 120, url: salesforce },
    { width: 140, url: twilio },
    { width: 130, url: zoom },
    { width: 140, url: make },
    { width: 200, url: ghl },
    { width: 300, url: calendly },
  ];

  return (
    <div className="carousel__container">
      <div className="carousel__title">
        Our Trusted Integrations:
      </div>
      <div className="carousel__track">
        {[...logos, ...logos].map(icon => (
          <div className="carousel__item">
            <img width={icon.width} src={icon.url} alt="integrationLogo" loading="lazy" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carrousel;