import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export const Reveal = ({ children, width = "fit-content", height= "unset", hidden1, visible1, hidden2, visible2}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-20%"});

    const mainControls = useAnimation();
    const slideControls = useAnimation();

    useEffect(() => {
        if (!isInView) return;
        mainControls.start("visible");
        slideControls.start("visible");
    }
    , [isInView, mainControls, slideControls]);

    return (
        <div ref={ref} style={{position: "relative", width, height, overflow: "hidden" }}>
            <motion.div
                variants={{
                    hidden: hidden1,
                    visible: visible1
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.5, delay: 0.25, ease: "easeInOut"}}

            >{children}</motion.div>
            <motion.div
                variants={{
                    hidden: hidden2,
                    visible: visible2
                }}
                initial="hidden"
                animate={slideControls}
                transition={{ duration: 0.5, ease: "easeIn"}}
                style={{position: "absolute", top: 4, left: 0, bottom: 4, right: 0, background: "transparent", zIndex: 20}}
            ></motion.div>
        </div>
    );
}