import React from "react";
import Referral from "src/components/ReferralProgram/Referral01";
import 'src/css/font.css';

function ReferralPage() {
    return (
        <div>
            <Referral />
        </div>
    );
}

export default ReferralPage;