import React from "react";
import Home01 from "src/components/home/Home01";
import Home02 from "src/components/home/Home02";
import Home03 from "src/components/home/Home03";
import Home04 from "src/components/home/Home04";
import Home05 from "src/components/home/Home05";
import 'src/css/font.css';

function Home() {
    return (
        <div>
            <Home01 />
            <Home02 />
            <Home03 />
            <Home04 />
            <Home05 />
        </div>
    );
}

export default Home;