import React from "react";
import Contact01 from "src/components/contact/Contact01";
import 'src/css/font.css';

function Contact() {
    return (
        <div>
            <Contact01 />
        </div>
    );
}

export default Contact;