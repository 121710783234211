import React from "react";
import './home.css';
import { Reveal } from "../common/Reveal";

function Home05() {
    const hidden1 = {
        opacity: 0,
        y: "100%"
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: "100%"
    }

    const visible2 = {
        left: "100%"
    }
    return (
        <div className="home_05">
            <div className="container">
                <div className="row">
                    <div className="home_05__content">
                        <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                            <div className="home_05__content__center">

                                <div className="home_05__content__title">
                                    BEGIN YOUR JOURNEY WITH <b>ALL RISE AI</b>
                                </div>
                                <div className="home_05__content__cta">
                                    <a href="/contact" className="cta-btn">Get Started</a>
                                </div>
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home05;