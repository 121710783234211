import React from "react";
import '../css/App.css';
import '../css/font.css';

import '../components/ReferralProgram/referral.css'


function RefTerms() {
    return (
        <div className="container">
            <div className="row">
                <div className="referral-legal-container">
                    <div className="referral-legal-section">
                        <h1>Affiliate Program Terms and Conditions</h1>
                        <ol>
                            <li><b>Commission Structure</b>
                                <ul>
                                    <li>Affiliates will receive a 10% recurring commission for the first 12 months of a referred client's active subscription.</li>
                                    <li>After the initial 12 months, the recurring commission will be reduced to 5% for the duration of the referred client's active subscription.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Commission Payments</b>
                                <ul>
                                    <li>Commissions will be paid for as long as the referred client remains active with All Rise AI.</li>
                                    <li>The first commission payout will be made after the referred client has been active in the program for 60 days.</li>
                                    <li>Subsequent commission payouts will be made on the 15th of each month for the previous month's active referred clients.</li>
                                    <li>If a referred client cancels or pauses their service, commission payments will be suspended during the inactive period.</li>
                                    <li>Commissions will be paid out according to the payment method determined by All Rise AI.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Marketing and Promotion</b>
                                <ul>
                                    <li>Affiliates must adhere to honest and accurate marketing practices when promoting All Rise AI's products or services.</li>
                                    <li>False promotions, misleading claims, or promises that cannot be fulfilled are strictly prohibited.</li>
                                    <li>Affiliates must clearly disclose their affiliate relationship with All Rise AI in all promotional materials and communications.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Referred Client Tracking</b>
                                <ul>
                                    <li>All Rise AI will track referred clients using unique affiliate links or codes provided to each affiliate.</li>
                                    <li>It is the affiliate's responsibility to ensure that their unique link or code is properly used to attribute referrals.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Dispute Resolution</b>
                                <ul>
                                    <li>Any disputes regarding referrals and commissions will be handled by the All Rise AI support team.</li>
                                    <li>Decisions regarding disputes will be made at the sole discretion of All Rise AI management.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Program Modifications</b>
                                <ul>
                                    <li>All Rise AI reserves the right to modify, suspend, or terminate the affiliate program at any time without prior notice.</li>
                                    <li>Changes to the terms and conditions will be communicated to affiliates and will take effect as specified by All Rise AI.</li>
                                </ul>
                            </li>
                        </ol>
                        <p>
                            By signing up for the All Rise AI affiliate program, you agree to abide by these terms and conditions. Failure to comply with these terms may result in the suspension or termination of your affiliate account and the forfeiture of any unpaid commissions.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default RefTerms;