import React, { useEffect } from "react";
import './thankyou.css';
import { Reveal } from "../common/Reveal";

function ThankyouCheckout() {
    const hidden1 = {
        opacity: 0,
        y: 75
    }
    const visible1 = {
        opacity: 1,
        y: 0
    }

    const hidden2 = {
        left: 0
    }

    const visible2 = {
        left: "100%"
    }

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'formSubmitted') {
                setTimeout(() => {
                    window.location.href = "/thankyou";
                }, 1000);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };

    }, []);

    return (
        <div className="thankyou_01">
            <div className="container">
                <div className="row">
                    <div className="thankyou_01__content">
                        <div className="thankyou_01__content__top">
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="thankyou_01__content__title">
                                    Thank You for Your Purchase!
                                </p>
                            </Reveal>
                            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
                                <p className="thankyou_01__content__description">
                                    Thank you for choosing us! We're excited to have you on board. Please check your email for onboarding instructions and next steps. We're here to help you get started smoothly! If you have any questions, feel free to reach out. Welcome aboard!                                </p>
                            </Reveal>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThankyouCheckout;