import React from "react";
import Pricing01 from "src/components/pricing/Pricing01";
import 'src/css/font.css';

function Pricing() {
    return (
        <div>
            <Pricing01 />
        </div>
    );
}

export default Pricing;