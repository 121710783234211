import React, { useState } from "react";
import { cards } from "./constants"

function Cases() {
  const [expandedCards, setExpandedCards] = useState({});

  const fileUrl = "https://intakeondemand-my.sharepoint.com/:b:/g/personal/fernando_allriseai_com/EdYJyeospsFOv1WM0O9ABtIBMBwjPeJeZsXNTH3I5kC3yw?e=WIrpmO"

  let counter = 0;
  let inverted = false;

  const toggleCardExpansion = (index) => {
    const offset = index % 2 === 0 ? 1 : -1;
    setExpandedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
      [index + offset]: !prev[index],
    }));
  };

  return (
    <div>
      <div className="landing_01__cases__grid">
        {cards.map((card, index) => {
          counter = counter + 1;
          if (counter === 2) {
            inverted = !inverted
            counter = 0;
          }
          return (
            <div className={`landing_01__cases__card ${inverted ? "" : "inverted"}`} key={index}>
              <div>
                <div className="landing_01__cases__card__title">{card.title}</div>
                <div className={`landing_01__cases__card__subtitle ${inverted ? "" : "inverted"}`}>Callenge</div>
                <div className={`landing_01__cases__card__content expanded ${inverted ? "" : "inverted"}`}>{card.challenge}</div>
                {expandedCards[index] && (
                  <>
                    <div className={`landing_01__cases__card__subtitle ${inverted ? "" : "inverted"}`}>Solution</div>
                    <div className={`landing_01__cases__card__content expanded  ${inverted ? "" : "inverted"}`}>{card.solution}</div>
                    <div className={`landing_01__cases__card__subtitle  ${inverted ? "" : "inverted"}`}>Implementation</div>
                    <div className={`landing_01__cases__card__content expanded  ${inverted ? "" : "inverted"}`}>{card.implementation}</div>
                    <div className={`landing_01__cases__results__section ${inverted ? "" : "inverted"}`}>
                      <div>Results:</div>
                      {card.results.map((result, idx) => (
                        <div key={idx} className={`landing_01__cases__results__item ${inverted ? "" : "inverted"}`}>
                          {result}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <button
                className={`landing_01__cases__read__more__btn ${inverted ? "" : "inverted"}`}
                onClick={() => toggleCardExpansion(index)}
              >
                {expandedCards[index] ? "Read Less" : "Read More"}
              </button>
            </div>
          )
        }
        )}
      </div>
      <a href={fileUrl} target="_blank" rel="noopener noreferrer" style={{ display: "flex", justifyContent: "center", textDecoration: "none" }}>
        <div className="landing_01__file__btn">
          Deep dive into our Case Studies
        </div>
      </a>
    </div>

  );
}

export default Cases;
