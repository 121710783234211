import React from "react";
import '../css/App.css';
import '../css/font.css';
import DisclaimerComponent from "src/components/old/legal/disclaimer";


function Disclaimer() {
    return (
        <div>
            <DisclaimerComponent />
        </div>
    );
}

export default Disclaimer;