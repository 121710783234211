import React from "react";
import '../css/App.css';
import '../css/font.css';
import TermsComponent from "src/components/old/legal/terms";


function Terms() {
    return (
        <div>
            <TermsComponent />
        </div>
    );
}

export default Terms;